/* ========================================================================
 * __DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $("#permissionCheck").click(function() {
          if (document.getElementById("permissionCheck").checked) {
            $("#createBtn").prop("disabled", false);
          } else {
            $("#createBtn").prop("disabled", true);
          }
        });

        $("#permissionCheckLegal").click(function() {
          if (document.getElementById("permissionCheckLegal").checked) {
            $("#acceptLegal").prop("disabled", false);
          } else {
            $("#acceptLegal").prop("disabled", true);
          }
        });
        // JS to control wheel menu on boar and newer manuals
        $(document).ready(function() {
          $("#chapterName").text($("#item-1").data("pagetitle"));
          $("#trigger").attr("href", $("#item-1").data("pageurl"));
        });
        $(".wheel-item").click(function() {
          var parentSvg = $(this)
            .parent()
            .parent()
            .attr("id");
          var rotateTo = $(this).data("rotate");
          var pageTitle = $(this).data("pagetitle");
          var pageUrl = $(this).data("pageurl");
          $("#chapterName").text(pageTitle);
          $("#trigger").attr("href", pageUrl);
          $("#" + parentSvg).removeClass();
          $("#" + parentSvg).addClass("rotateClass");
          $("#" + parentSvg).addClass(rotateTo);
          $(this)
            .siblings()
            .removeClass("active");
          $(this).addClass("wheel-item active");
        });
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
